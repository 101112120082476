* {
  margin: 0;
  padding: 0;
  list-style: none;
}

:root {
  --color-primary: black;
  --color-secondary: #fdc60c;
  --color-text: white;
  --color-box: rgba(40,40,40,100);
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--color-primary);
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 10vh;
  backdrop-filter: blur(5px);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  padding: .15rem 1.5rem;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--color-text);
}


a:hover {
  font-weight: bold;
  color: var(--color-text);
  text-decoration: underline;
}

nav ul li {
  display: inline;
  margin: 0 15px;
  cursor: pointer;
  color: var(--color-text);
}

.hero-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.hero {
  color: white;
  text-align: center;
  padding: 100px 20px;
}

.hero-side-img {
  width: 300px;
}

.hero-logo {
  width: 250px;
  height: 250px;
}

.hero h2 {
  font-size: 2.5em;
}

.hero p {
  font-size: 1.5em;
}

button {
  background-color: #e76f51;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

.produtos {
  padding: 40px 20px;
  text-align: center;
}

.produto {
  display: inline-block;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  width: 200px;
}

.produto img {
  width: 100%;
  border-radius: 5px;
}

.sobre, .depoimentos, .contato {
  padding: 40px 20px;
  text-align: center;
}

footer {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form input, form textarea {
  margin: 10px 0;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form button {
  background-color: #e76f51;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
