@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

:root {
  --color-primary: white;
  --color-secondary: #FFEC00;
  --color-text: black;
  --color-box: rgba(255, 238, 0, 57%);
}

.body {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/img/tortinhas-backgroung.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: var(--color-primary);
  border-radius: 20px;
  width: 80%;
  height: 80%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.501);
}

.logo {
  width: 150px;
  height: auto;
  position: absolute;
  top: calc(0.1 * 100vh - 75px);
  left: calc(50% - 75px);
  background-color: var(--color-primary);
  border-radius: 50%;
}

.container-info {
  width: calc(100% - 8rem);
  height: calc(100% - 8rem);
  padding: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.tortinhas-div {
  height: 100%;
  width: 40%;
}

.tortinhas-img {
  height: 100%;
  margin-left: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
}

.linha-vertical {
  position: relative; 
  height: 80%;
  width: 0;
  border-left: 1px solid black;
}

.info {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

h1 {
  font-family: "Cinzel", serif;
  font-weight: normal;
  font-style: normal;
}

.spam {
  font-family: "JetBrains Mono", monospace;
}

.social-icons {
  margin: 1.2rem;
}

.links {
  height: 80%;
  width: 100%;
}

.links a {
  width: 100%;
}

.link {
  padding: 1rem;
  width: 100%;
  background-color: var(--color-box);
  margin: auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
}

.copyrigth {
  position: absolute;
  text-align: center;
  bottom: 1px;
}


@media (max-width: 1000px) {
  .body {
    background-image: url('../assets/img/tortinhas.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .container {
    height: min-content;
    margin: 20vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    top: calc(0.2 * 100vh - 75px);
  }
  
  .container-info {
    flex-direction: column;
    padding: 4rem .5rem .5rem .5rem;
  }
  
  .tortinhas-div, .linha-vertical {
    display: none;
  }
  
  .info {
    margin-top: 2rem;
    width: 100%;
  }
  
  .link {
    width: auto;
  }
}